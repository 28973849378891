import { gql } from '@apollo/client/core';
import { userFragment } from '~/graphql/fragments/users';

export const USERS_QUERY_LIST = gql`
  ${userFragment}
  query UsersQuery {
    users {
      ...UserFragment
    }
  }
`;

export const USER_QUERY = gql`
  ${userFragment}
  query UserByPk($id: String!) {
    users_by_pk(id: $id) {
      ...UserFragment
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  ${userFragment}
  mutation UpdateUser($object: users_set_input!, $id: String!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...UserFragment
    }
  }
`;
