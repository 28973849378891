import type { AxiosError } from 'axios';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import type { UserModule } from '~/types';

export const install: UserModule = ({ app }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          if (error && ((error as AxiosError).response?.status === 404 || (error as AxiosError).response?.status === 401))
            return false;

          if (failureCount >= 3)
            console.error(error);
          return false;
        },
      },
    },
  });
  app.use(VueQueryPlugin, { queryClient });
};
