import { gql } from '@apollo/client/core';
import { clientFragment } from '~/graphql/fragments/clients';

export const CLIENTS_QUERY_LIST = gql`
  ${clientFragment}
  query ClientQuery {
    clients {
      ...ClientFragment
    }
  }
`;

export const CLIENT_QUERY = gql`
  ${clientFragment}
  query ClientQueryByPk($id: Int!) {
    clients_by_pk(id: $id) {
      ...ClientFragment
    }
  }
`;

export const CLIENT_UPDATE = gql`
  ${clientFragment}
  mutation UpdateClient($object: clients_set_input!, $id: Int!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...ClientFragment
    }
  }
`;

export const CLIENT_INSERT = gql`
  ${clientFragment}
  mutation InsertClient($object: clients_insert_input!) {
    insert_clients_one(object: $object) {
      ...ClientFragment
    }
  }
`;
