import type { User, UserRoleEnum } from '@relief/types';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/vue';
import { provideApolloClient } from '@vue/apollo-composable';
import { Crisp } from 'crisp-sdk-web';
import { clone, cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { USERS_QUERY_LIST } from '~/graphql/users';
import { useGetCurrentUser, useGetUsers } from '~/queries/users';
import { fb_auth } from '~/services/firebase';
import apolloClient from '../composables/graphql';

provideApolloClient(apolloClient);

export const usersStore = defineStore('users', () => {
  const userId = ref(fb_auth.currentUser?.uid);
  const setUserId = (id: string) => {
    userId.value = id;
  };
  const { data: currentUserData, isLoading: isLoadingCurrentUser } = useGetCurrentUser(computed(() => !!userId.value));
  const { cloned: currentUser } = useCloned(currentUserData, { clone });
  const isAdmin = computed(() => currentUser.value?.role === 'admin_app');

  watch(currentUser, () => {
    Crisp.user.setEmail(String(currentUser.value?.email));
    Crisp.user.setNickname(`${currentUser.value?.first_name} ${currentUser.value?.last_name}`);
    Crisp.session.setData({
      client_id: currentUser.value?.client_id,
    });
    Sentry.setUser({
      email: currentUser.value?.email,
      id: currentUser.value?.id,
    });
    if (currentUser.value) {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('email', currentUser.value.email);
      identifyEvent.set('user_id', currentUser.value.id);
      identifyEvent.set('name', `${currentUser.value?.first_name} ${currentUser.value?.last_name}`);
      amplitude.identify(identifyEvent);
    }
  });

  const { data: usersData, isLoading: isLoadingUsers } = useGetUsers(computed(() => !!userId.value));
  const { cloned: users } = useCloned(usersData, { clone });
  function getUserById(id: string) {
    return users.value?.find(row => row.id === id);
  };

  async function loadUser(uid: string) {
    if (uid) {
      const baseUser = getUserById(uid);
      if (!baseUser) {
        return;
      }
      const user = cloneDeep(baseUser);
      const tokenResult = await fb_auth.currentUser?.getIdTokenResult();
      const claims = tokenResult?.claims;
      if (claims && claims['https://hasura.io/jwt/claims']) {
        const hasuraClaims = claims['https://hasura.io/jwt/claims'] as Record<string, string>;
        user.role = hasuraClaims['x-hasura-default-role'] as UserRoleEnum;
      }

      user.language = navigator.language.substring(0, 2);
      currentUser.value = user;
    }
  };

  async function loadUsers() {
    const users_list = await apolloClient.query<{ users: User[] }>({ query: USERS_QUERY_LIST });
    users.value = users_list.data?.users ?? [];
  };

  function filterUsers(ids: string[]) {
    return users.value?.filter((u) => {
      return ids?.length && ids?.includes(u?.id);
    }) || [];
  };

  return {
    setUserId,
    currentUser,
    isLoadingCurrentUser,
    me: currentUser,
    isAdmin,
    users,
    isLoadingUsers,
    getUserById,
    loadUser,
    loadUsers,
    filterUsers,
  };
});
