import type { Unsubscribe } from 'firebase/auth';
import { Crisp } from 'crisp-sdk-web';
import { SAMLAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import apolloClient from '~/composables/graphql';
import { loadStores } from '~/controllers/stores';
import { fb_auth, on_auth_state_changed } from '~/services/firebase';
import { settingsStore } from '~/stores/settings';
import { usersStore } from '~/stores/users';

let unsubscribe: Unsubscribe | null = null;

export async function loadUser() {
  unsubscribe = on_auth_state_changed(fb_auth, (user) => {
    console.log('User state changed', !!user);
    if (user) {
      const userId: string = user?.uid ? user?.uid : '';
      usersStore().setUserId(userId);
      loadStores(userId); // eslint-disable-line ts/no-floating-promises
    }
    else {
      settingsStore().setStoresLoadingStating();
    }
  }, (error) => {
    console.error('Error on auth state change', error);
  });
}

export function getUserId() {
  const user = fb_auth.currentUser;
  if (user) {
    const userId: string = user?.uid ? user?.uid : '';
    return userId;
  }
  return '';
}

export async function signIn(email: string, password: string) {
  await signInWithEmailAndPassword(fb_auth, email, password);
  await loadUser();
}

export async function signInSSO(saml: string) {
  const provider = new SAMLAuthProvider(saml);
  await signInWithPopup(fb_auth, provider);
  await loadUser();
}

export async function logout() {
  Crisp.session.reset();
  unsubscribe?.();
  await fb_auth.signOut();
  await apolloClient.clearStore();
}
