import { gql } from '@apollo/client/core';

export const clientFragment = gql`
fragment ClientFragment on clients {
  id
  name
  slug
  address
  zip_code
  city
  country
  phone_number
  company_number
  vat_number
  attachments

  _created_at
  _created_by
  _updated_at
  _updated_by
}
`;
