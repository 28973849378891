<script setup lang="ts">
const { t } = useI18n();
const router = useRouter();
router.push('/quotes');
</script>

<template>
  <div class="h-full w-[calc(100vw-2.5rem)] flex flex-col overflow-y-scroll text-primary md:w-[calc(100vw-15rem)]">
    <UiHeader :title="t('global.dashboard')" />
    <div class="flex flex-col gap-4 p-4 md:px-8 md:py-6 md:pb-0">
      Home
    </div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
