import { cloneDeep } from 'lodash';

export function cleanJsonBeforeSend<T>(options: {
  jsonObject: T extends Record<string, any> ? T : any;
  keys?: string[];
}) {
  const newObject = cloneDeep(options.jsonObject);
  const removeKeys = [
    '_created_at',
    '_updated_at',
    '_created_by',
    '_updated_by',
    '__typename',
    ...(options.keys || []),
  ];
  removeKeys.forEach((key) => {
    delete newObject[key];
  });

  return newObject;
}

export function manageVersion<T>(options: { jsonObject: { version: number } | Record<string, any> }) {
  const newObject = cloneDeep(options.jsonObject);
  newObject.version += 1;
  return newObject;
}
