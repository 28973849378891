import { i18n } from '~/modules/i18n';
import { clientsStore } from '~/stores/clients';

import { settingsStore } from '~/stores/settings';
import { usersStore } from '~/stores/users';

let uid = '';
const t = i18n.global.t;

export async function loadStores(userId: string) {
  uid = userId;

  await usersStore().loadUsers();
  settingsStore().setStoresLoadingMsg(t('stores.users_loading'));
  await usersStore().loadUser(uid);
  settingsStore().setStoresLoadingMsg(t('stores.data_loading'));
  await loadAppStores();
}

async function loadAppStores() {
  await clientsStore().loadClient();
  settingsStore().setStoresLoadingStating(true);
}
